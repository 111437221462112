//import React from 'react';


function App() {
    const pName = window.location.pathname;
    const searchQuery = pName.substring(0, pName.length - 1);
    const lastItem = searchQuery.substring(searchQuery.lastIndexOf('/') + 1);
    const url = new URL('http://qrxmenu.com?n=' + lastItem);
  
    document.location.href = url;

}

export default App;